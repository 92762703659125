import React from 'react';
import { Link } from 'react-router-dom';

export default function ChildPage(props) {
  const post = props.post;
  function renderContent(content) {
    return { __html: content };
  }
  return (
    <div className="col-article">
      <div className="block-article">
        <div className="block-article__content">
          <div className="block-article__header">
            <h4
              className="block-article__header--title"
              dangerouslySetInnerHTML={renderContent(post.post_title)}
            />
          </div>
          <div
            className="block-article__text"
            dangerouslySetInnerHTML={renderContent(post.post_excerpt)}
          />
          <span className="block-article__btn">
            <span className="icon icon-chevron-circle-right"></span>
            <span className="btn-link__label">Read More</span>
          </span>
          <Link
            to={`/${post.post_type}/${props.parent}/${post.post_name}-${post.ID}`}
            className="block-article__link"
          ></Link>
        </div>
      </div>
    </div>
  );
}
