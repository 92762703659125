import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Feed } from '../../components';
import * as postsActionCreators from '../../reducers/posts';
import Loader from '../../components/Loader';

class RecommendedContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAndHandlePosts({
      tag: this.props.tag,
      type: this.props.type,
    });
  }

  render() {
    let template;

    if (this.props.isFetching) {
      template = <Loader />;
    } else {
      template = (
        <Feed
          isFetching={this.props.isFetching}
          error={this.props.error}
          posts={this.props.posts}
          title="Recommended Reading"
        />
      );
    }

    return <div>{template}</div>;
  }
}

function mapStateToProps({ posts }) {
  return {
    isFetching: posts.isFetching,
    error: posts.error,
    posts: posts.posts ? posts.posts : [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...postsActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendedContainer);
