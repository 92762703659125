import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import Router from './common/routes';
import AuthContextProvider from './common/AuthContext';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-NZQCHXB',
};

TagManager.initialize(tagManagerArgs);

const Root = (
  <Provider store={configureStore()}>
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
