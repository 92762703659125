import React from 'react';
import { connectHits, connectRefinementList } from 'react-instantsearch-dom';
import { SearchResult } from '../../components';
import { typeColor } from '../../helpers/utils';
import { Link } from 'react-router-dom';

const Hits = ({ hits }) => {
  return (
    <div class="hits">
      <div className="row">
        {hits.map(hit => {
          return <SearchResult post={hit} key={hit.objectID} />;
        })}
      </div>
    </div>
  );
};

export const SuggestionHit = ({ hit }) => {
  return (
    <Link to={`/${hit.object_type}/${hit.slug}-${hit.objectID}`}>
      <div
        className={typeColor(
          hit.object_type,
          hit.terms,
          'suggestion-container'
        )}
      >
        <span className="suggestion-item">{hit.title}</span>
        <ul className="suggestion-meta">
          <li className="suggestion-meta__item">
            {hit.authors && hit.authors.length > 0 ? (
              <span className="suggestion-meta-label">Authors:</span>
            ) : null}
            {hit.authors && hit.authors.length > 0
              ? hit.authors.map(item => (
                  <span className="suggestion-meta-title">{item}</span>
                ))
              : null}
          </li>
          <li className="suggestion-meta__item">
            {hit.organizations && hit.organizations.length > 0 ? (
              <span className="suggestion-meta-label">Organizations:</span>
            ) : null}
            {hit.organizations && hit.organizations.length > 0
              ? hit.organizations.map(item => (
                  <span className="suggestion-meta-title">{item}</span>
                ))
              : null}
          </li>
        </ul>
      </div>
    </Link>
  );
};

export const CustomHits = connectHits(Hits);
export const VirtualMenu = connectRefinementList(() => null);
