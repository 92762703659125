import React from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
//import ReactGA from 'react-ga';

import { bindActionCreators } from 'redux';
import * as suggestActionCreators from '../../reducers/suggest';
import { Types } from '../../components';
import { typeColor } from '../../helpers/utils';

/* ----------------- */
/*    MyComponent    */
/* ----------------- */

function getSuggestionValue(suggestion) {
  return suggestion._source.title;
}

function shouldRenderSuggestions(value) {
  return value.trim().length > 3;
}

function renderSuggestion(suggestion) {
  return (
    <div
      className={typeColor(
        suggestion._source.object_type,
        suggestion._source.terms,
        'suggestion-container'
      )}
    >
      <span className="suggestion-item">{suggestion._source.title}</span>
      <ul className="suggestion-meta">
        <li className="suggestion-meta__item">
          {suggestion._source.authors &&
          suggestion._source.authors.length > 0 ? (
            <span className="suggestion-meta-label">Authors:</span>
          ) : null}
          {suggestion._source.authors && suggestion._source.authors.length > 0
            ? suggestion._source.authors.map(item => (
                <span className="suggestion-meta-title">{item}</span>
              ))
            : null}
        </li>
        <li className="suggestion-meta__item">
          {suggestion._source.organizations &&
          suggestion._source.organizations.length > 0 ? (
            <span className="suggestion-meta-label">Organizations:</span>
          ) : null}
          {suggestion._source.organizations &&
          suggestion._source.organizations.length > 0
            ? suggestion._source.organizations.map(item => (
                <span className="suggestion-meta-title">{item}</span>
              ))
            : null}
        </li>
      </ul>
    </div>
  );
}

class Box extends React.Component {
  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, sectionIndex, method }
  ) {
    event.stopPropagation();
    this.context.router.push(
      `/${suggestion._source.object_type}/${suggestion._source.slug}-${suggestion._id}`
    );
  }

  componentDidMount() {
    if (this.props.options) {
      this.props.setFilter(this.props.options);
      this.props.getSuggestionsTypes();
    }
  }
  componentWillUnmount() {
    this.props.resetFilter();
  }
  selectType(event) {
    if (event.target.value !== 'all') {
      if (
        event.target.value === 'qna' ||
        event.target.value === 'skills' ||
        event.target.value === 'digest'
      ) {
        this.props.options.object_type = event.target.value;
        this.props.setFilter({ ...this.props.options });
      } else {
        this.props.setFilter({
          ...this.props.options,
          type: event.target.value,
        });
      }
    } else {
      this.props.setFilter(this.props.options);
    }
  }
  submitSearch(e) {
    e.preventDefault();

    var data = {};

    if (this.props.value) {
      data.q = this.props.value;
    }

    if (this.props.options && this.props.options.object) {
      data.object = this.props.options.object;
    }

    if (
      this.props.options &&
      this.props.options.object_type &&
      this.props.options.object_type !== 'all'
    ) {
      data.object = this.props.options.object_type;
    } else {
      if (
        this.props.options &&
        this.props.options.objectAuto &&
        this.props.options.objectAuto !== 'all'
      ) {
        data.object = this.props.options.objectAuto;
      }
    }

    if (this.props.filter.type) {
      data.type = this.props.filter.type;
    }

    if (this.props.options.taxonomy) {
      data[this.props.options.taxonomy.type] = this.props.options.taxonomy.name;
    }

    /*ReactGA.event({
      category: 'Search',
      action: data.q,
      label: window.location.pathname,
    });*/

    //browserHistory.push({ pathname: '/results', query: data });
  }
  render() {
    const {
      value,
      suggestions,
      isLoading,
      onChange,
      onSuggestionsUpdateRequested,
    } = this.props;

    const inputProps = {
      placeholder: this.props.placeholder || 'Enter search term...',
      value,
      onChange,
    };

    return (
      <form onSubmit={this.submitSearch}>
        {isLoading ? (
          <div className="sk-search-box__loader sk-spinning-loader">
            <div></div>
            <div></div>
          </div>
        ) : (
          <div className="sk-search-box__icon"></div>
        )}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsUpdateRequested={onSuggestionsUpdateRequested}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <Types
          type={this.props.options.objectAuto}
          types={this.props.types}
          change={this.selectType}
        />
        <input
          type="submit"
          value="search"
          className="sk-search-box__action"
          data-qa="submit"
        />
      </form>
    );
  }
}

function mapStateToProps({ suggest }, props) {
  const { value, suggestions, isLoading, types, filter } = suggest;

  return {
    value,
    suggestions,
    types,
    isLoading,
    filter,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...suggestActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Box);
