import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { authContext } from '../../common/AuthContext';
import { fetchLogin } from '../../helpers/api';
import Loader from '../Loader';

const AuthenticateForm = () => {
  const username = useRef(null);
  const password = useRef(null);

  const [loading, setLoading] = useState(false);
  const [error, setSetError] = useState('');
  const auth = React.useContext(authContext);

  const authHandler = async () => {
    try {
      setLoading(true);
      const { data: userData } = await fetchLogin({
        username: username.current.value,
        password: password.current.value,
      });

      auth.setAuthStatus(userData.token);
    } catch (error) {
      if (error.response) {
        setSetError(error.response.data);
      } else if (error.request) {
        setSetError('An unexpected error occurred. Please try again later.');
      } else {
        setSetError('An unexpected error occurred. Please try again later.');
      }

      setLoading(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    await authHandler();
  };

  return (
    <form className="login-form" onSubmit={onSubmit}>
      {error ? <div className="login-box__alert--error">{error}</div> : null}

      <div className="login-form__group">
        <label for="inputEmail" className="sr-only">
          Email
        </label>
        <div className="login-form__email">
          <input
            type="text"
            ref={username}
            className="login-form--input login-form--user"
            placeholder="Username"
            required
          />
        </div>

        <label for="inputPassword" className="sr-only">
          Password
        </label>
        <div className="login-form__password">
          <input
            type="password"
            ref={password}
            className="login-form--input"
            placeholder="Password"
            required
          />
        </div>
      </div>
      <div className="login-form__group">
        <div className="login-form__button">
          {loading ? (
            <Loader />
          ) : (
            <button
              disabled={loading}
              type="submit"
              className="login-form--btn"
            >
              {loading ? 'Submitting' : 'Log In'}
            </button>
          )}
        </div>
        <a
          className="login-form__link"
          href="https://analystinstitute.org/wp-login.php?action=lostpassword"
        >
          Forgot your Password?
        </a>
      </div>
    </form>
  );
};

export default AuthenticateForm;
