import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Navigation, Footer, Members } from '../../components';
import { connect } from 'react-redux';
import * as userActionCreators from '../../reducers/users';
import * as navActionCreators from '../../reducers/navigation';
import { authContext } from '../../common/AuthContext';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader';

class MainContainer extends React.Component {
  componentDidMount() {
    Promise.all([
      this.props.fetchAndHandleNav(587),
      this.props.fetchAndHandleNav(832),
      this.props.fetchAndHandleNav(567),
    ]);
  }

  render() {
    return (
      <div>
        <Navigation
          menu={this.props.nav}
          main={587}
          secondary={567}
          isAuthenticated={this.props.isAuthenticated}
        />
        <div className="section-container">
          <RootContainer>{this.props.children}</RootContainer>
        </div>
        <Footer menu={this.props.nav} main={832} />
      </div>
    );
  }
}

const RootContainer = props => {
  const { auth } = React.useContext(authContext);
  if (!auth) {
    return <Members />;
  }
  return props.children;
};

export const AccessContainer = props => {
  const { setAuthStatus } = React.useContext(authContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const {
      match: { params },
    } = props;

    const fetchData = async () => {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/me`, {
        headers: {
          authorization: `Bearer ${params.access_token}`,
        },
      });

      if (!res.ok) {
        setAuthStatus(null);
      }

      setAuthStatus({
        access_token: params.access_token,
      });
      setLoading(false);
    };

    fetchData();
  }, [props, setAuthStatus]);

  return loading ? <Loader /> : <Redirect to="/" />;
};

function mapStateToProps({ users, navigation }) {
  const { isAuthenticated, errorMessage, isFetching } = users;

  return {
    isAuthenticated,
    isFetching,
    errorMessage,
    nav: navigation ? navigation : { items: [] },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActionCreators,
      ...navActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer);
