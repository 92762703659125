import React from 'react';

export default function Relevance(props) {
  var headingStyle = {
    textTransform: 'uppercase',
    color: '#0b0b0b',
    fontSize: '.6rem',
    fontWeight: 600,
  };

  return (
    <div className="sort m-b-1">
      <div className="meta-list-container m-b-1">
        <span style={headingStyle}>{props.title}</span>
      </div>
      <select
        className="form-control c-select"
        style={{ color: '#000' }}
        value={props.currentRefinement}
        onChange={e => props.refine(e.target.value)}
      >
        {props.items.map(item => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );
}
