import React from 'react';
import { Filter } from '../../components';
import _ from 'lodash';

export default function Filters({ aggregations, query, updateFilter }) {
  function checkInput(type, item) {
    let key = '';

    switch (type) {
      case 'tags':
        key = 'post_tag';
        break;
      case 'authors':
        key = 'authors';
        break;
      case 'organizations':
        key = 'organizations';
        break;
      case 'types':
        key = 'object';
        break;
      case 'format':
        key = 'type_name';
        break;
      case 'year':
        key = 'year';
        break;
      default:
        key = '';
    }

    if (query[key] && query[key].indexOf(item.key) > -1) {
      return true;
    } else {
      return false;
    }
  }

  const headingStyle = {
    textTransform: 'uppercase',
    color: '#0b0b0b',
    fontSize: '.6rem',
    fontWeight: 600,
  };

  return (
    <div className="filter-container">
      <div className="meta-list-container m-b-1">
        <span style={headingStyle}>Filters</span>
      </div>
      {_.map(aggregations, (filter, key) => {
        if (key !== 'doc_count' && filter.buckets.length > 0) {
          return (
            <Filter
              key={key}
              updateFilter={item => {
                updateFilter({ type: key, value: item.key });
              }}
              checkInput={item => checkInput(key, item)}
              options={{ title: key, bucket: filter.buckets }}
            />
          );
        }
      })}
    </div>
  );
}
