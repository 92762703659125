import { fetchNav } from '../helpers/api';

const FETCHING_NAV = 'FETCHING_NAV';
const FETCHING_NAV_ERROR = 'FETCHING_NAV_ERROR';
const FETCHING_NAV_SUCCESS = 'FETCHING_NAV_SUCCESS';

function fetchingNav() {
  return {
    type: FETCHING_NAV,
  };
}

function fetchingNavError(error) {
  return {
    type: FETCHING_NAV_ERROR,
    error: error,
  };
}

function fetchingNavSuccess(nav, name) {
  return {
    type: FETCHING_NAV_SUCCESS,
    nav,
    name,
  };
}

export function fetchAndHandleNav(id) {
  return function(dispatch) {
    dispatch(fetchingNav());

    fetchNav('/wp-json/wp-api-menus/v2/menus/', id, false)
      .then(nav => {
        dispatch(fetchingNavSuccess(nav, id));
      })
      .catch(error => dispatch(fetchingNavError(error)));
  };
}

const initialState = { isFetching: true, error: '' };

export default function navigation(state = initialState, action) {
  switch (action.type) {
    case FETCHING_NAV:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHING_NAV_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCHING_NAV_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        [action.name]: action.nav,
      };
    default:
      return state;
  }
}
