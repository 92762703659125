import React from 'react';
import _ from 'lodash';

export default function Filter({
  title,
  items,
  currentRefinement,
  refine,
  ...rest
}) {
  return (
    <div className="filter-block js--collapse">
      <button
        className="collapse-header"
        data-toggle="collapse"
        data-target={'#' + title.toLowerCase()}
      >
        <h4 className="filter-heading">{title}</h4>
        <span className="icon icon-angle-down"></span>
      </button>

      <div className="collapse" id={title.toLowerCase()} aria-expanded="true">
        <div className="collapse__container">
          <div className="filter-block__input-stack">
            {title === 'Year'
              ? _.sortBy(items, item => parseInt(item.label))
                  .reverse()
                  .map(item => (
                    <label className="filter-block__checkbox" key={item.label}>
                      <input
                        onChange={e => refine(item.value)}
                        checked={item.isRefined}
                        type="checkbox"
                      />
                      <span className="c-indicator"></span>
                      {item.label}
                    </label>
                  ))
              : items.map(item => (
                  <label className="filter-block__checkbox" key={item.key}>
                    <input
                      onChange={e => refine(item.value)}
                      checked={item.isRefined}
                      type="checkbox"
                    />
                    <span className="c-indicator"></span>
                    {item.label === 'qna' ? 'Q & A' : item.label}
                  </label>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
