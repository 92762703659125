import React from 'react';
import { Link } from 'react-router-dom';

export default function TagList({ menu }) {
  function createMarkup(title) {
    return { __html: title };
  }

  return (
    <div className="search-meta-list-container">
      <ul className="meta-list">
        {menu && menu.length > 0
          ? menu.map(({ url, title, object_id, object, slug, type }) => (
              <li className="meta-list__item--tag" key={object_id}>
                {object === 'custom' ? (
                  <Link to={url}>
                    <span
                      dangerouslySetInnerHTML={createMarkup(title)}
                      className="meta-list__item--link"
                    />
                  </Link>
                ) : null}
                {type === 'taxonomy' ? (
                  <Link
                    to={`/archive/${object}/${slug}/${object_id}`}
                    className="meta-list__item--link"
                  >
                    <span dangerouslySetInnerHTML={createMarkup(title)} />
                  </Link>
                ) : null}
                {type === 'post_type' ? (
                  <Link
                    to={`/${object}/${slug}-${object_id}`}
                    className="meta-list__item--link"
                  >
                    <span dangerouslySetInnerHTML={createMarkup(title)} />
                  </Link>
                ) : null}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}
