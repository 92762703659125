import React from 'react';
import { typeColor } from '../../helpers/utils';
import {
  Entry,
  Title,
  Tags,
  Meta,
  PubDate,
  Accordion,
  Related,
} from '../../components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import Loader from '../Loader';

function bookmarkLink() {
  return window.location.href;
}

export default function Single({ post, isFetching, error, toggleAll, toggle }) {
  let contentClass = 'page-content';
  let sidebarClass = 'page-sidebar';

  if (post.template === 'template-fw.php') {
    contentClass += ' page-content--fw';
    contentClass += ' page-sidebar--fw';
  }

  if (isFetching || !post) return <Loader />;
  return (
    <div className="section-wrapper">
      <Helmet>
        <title>
          {post.title ? post.title.rendered : null} - Analyst Institute
        </title>
      </Helmet>
      <div className={typeColor(post.type, post.terms, 'content-row')}>
        <main className={contentClass}>
          {post.title ? <Title post={post} /> : null}
          {post.content ? <Entry post={post} /> : null}
          {post.subpages && post.subpages.length > 0
            ? post.subpages.map(({ title, url }) => (
                <Accordion
                  key={title}
                  toggleStatus={toggle}
                  summary={title}
                  details={url}
                />
              ))
            : null}
        </main>
        <aside className={sidebarClass}>
          <div className="sidebar__header">
            <h3 className="sidebar__title">More About This Articl se</h3>
          </div>
          {post.terms ? <Tags post={post} /> : null}
          <ul className="sidebar-infos">
            {post.authors && post.authors.length > 0 ? (
              <Meta title={'Authors'} meta={post.authors} />
            ) : null}
            {post.organisations && post.organisations.length > 0 ? (
              <Meta title={'Organizations'} meta={post.organisations} />
            ) : null}
            {post.date ? (
              <PubDate title={'Published Date'} date={post.date} />
            ) : null}
          </ul>
          <ul className="sidebar-actions">
            {post.subpages && post.subpages.length > 0 ? (
              <li className="sidebar-action">
                <span
                  className={toggle ? 'icon icon-collapse' : 'icon icon-expand'}
                ></span>
                <span className="sidebar-action__title">
                  {toggle ? 'Collapse All' : 'Expand All'}
                </span>
                <button
                  href="#"
                  onClick={e => toggleAll(e)}
                  className="sidebar-item--link"
                >
                  View
                </button>
              </li>
            ) : null}
            <CopyToClipboard text={bookmarkLink()}>
              <li className="sidebar-action">
                <span className="icon icon-bookmark"></span>
                <span className="sidebar-action__title">Copy Link</span>
              </li>
            </CopyToClipboard>
          </ul>
          {post.relatedposts && post.relatedposts.length > 0 ? (
            <Related title="Related Articles" posts={post.relatedposts} />
          ) : null}
        </aside>
      </div>
    </div>
  );
}
