import * as React from 'react';

/** Custom Hooks */

import { getStoredUserAuth } from '../helpers/utils';
import useAuthHandler from './AuthHandler';

export const authContext = React.createContext({
  auth: null,
  setAuthStatus: () => {},
  setUnauthStatus: () => {},
});

const { Provider } = authContext;

const AuthProvider = ({ children }) => {
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(
    getStoredUserAuth()
  );

  return (
    <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
      {children}
    </Provider>
  );
};

export default AuthProvider;
