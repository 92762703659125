import React from 'react';

export default function Tags({ title, meta }) {
  return (
    <li className="sidebar-info">
      <span className="sidebar-info__meta">{title}</span>
      {meta.map(item => (
        <h4 className="sidebar-info__title" key={item}>
          {item}
        </h4>
      ))}
    </li>
  );
}
