import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Logout } from '../../components';
import * as userActionCreators from '../../reducers/users';

class LogoutContainer extends React.Component {
  handleAuth(e) {
    e.preventDefault();
    this.props.logoutUser();
  }
  render() {
    return (
      <Logout
        isFetching={this.props.isFetching}
        error={this.props.error}
        onAuth={this.handleAuth}
      />
    );
  }
}

function mapStateToProps({ users }) {
  return {
    isFetching: users.isFetching,
    error: users.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutContainer);
