import React from 'react';
import { formatTimestamp } from '../../helpers/utils';

export default function Meta({ title, date }) {
  return (
    <li className="sidebar-info">
      <span className="sidebar-info__meta">{title}</span>
      <h4 className="sidebar-info__title">{formatTimestamp(date)}</h4>
    </li>
  );
}
