import { fetchPosts, fetchPost } from '../helpers/api';
import { verifyUser } from '../helpers/api';
import _ from 'lodash';
import { getStoredUserAuthToken } from '../helpers/utils';

const FETCHING_POSTS = 'FETCHING_POSTS';
const FETCHING_POSTS_ERROR = 'FETCHING_POSTS_ERROR';
const FETCHING_POSTS_SUCCESS = 'FETCHING_POSTS_SUCCESS';
const FETCHING_POST_SUCCESS = 'FETCHING_POST_SUCCESS';
const TOGGLE_ALL = 'TOGGLE_ALL';
const SHOW_SEARCH = 'SHOW_SEARCH';

function fetchingPosts() {
  return {
    type: FETCHING_POSTS,
  };
}

export function fetchingPostsError(error) {
  return {
    type: FETCHING_POSTS_ERROR,
    error,
  };
}

function fetchingPostsSuccess(posts) {
  return {
    type: FETCHING_POSTS_SUCCESS,
    posts,
  };
}

function fetchingPostSuccess(post) {
  return {
    type: FETCHING_POST_SUCCESS,
    post,
  };
}

function showSearch(value) {
  return {
    type: SHOW_SEARCH,
    showSearch: value,
  };
}

export function toggleAll(value) {
  return {
    type: TOGGLE_ALL,
    value,
  };
}

export function fetchAndHandlePosts(query) {
  return function(dispatch) {
    dispatch(fetchingPosts());

    fetchPosts(query, false)
      .then(posts => dispatch(fetchingPostsSuccess(posts)))
      .catch(error => dispatch(fetchingPostsError(error)));
  };
}

export function fetchAndHandlePost(query) {
  return function(dispatch) {
    dispatch(fetchingPosts());

    fetchPost(query, false)
      .then(post => {
        let token = getStoredUserAuthToken();

        verifyUser(token).then(response => {
          var user = response.data;

          if (_.includes(user.roles, 'gotv_video')) {
            var access = post.access;

            if (access === null) {
              dispatch(
                fetchingPostsError({
                  message: 'Not authorized to view this post.',
                })
              );
            } else if (access === 'gotv_video') {
              dispatch(fetchingPostSuccess(post));
              dispatch(showSearch(false));
            }
          } else {
            dispatch(fetchingPostSuccess(post));
            showSearch(true);
          }
        });
      })
      .catch(error => {
        if (error.response) {
          dispatch(fetchingPostsError({ message: error.response.data }));
        } else if (error.request) {
          dispatch(
            fetchingPostsError({
              message: 'An unexpected error occurred. Please try again later.',
            })
          );
        } else {
          dispatch(
            fetchingPostsError({
              message: 'An unexpected error occurred. Please try again later.',
            })
          );
        }
      });
  };
}

const initialState = {
  isFetching: true,
  error: '',
  toggle: false,
  showSearch: true,
};

export default function posts(state = initialState, action) {
  switch (action.type) {
    case FETCHING_POSTS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHING_POSTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCHING_POSTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        posts: action.posts,
      };
    case FETCHING_POST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        post: action.post,
      };
    case TOGGLE_ALL:
      return {
        ...state,
        toggle: action.value,
      };
    case SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.showSearch,
      };
    default:
      return state;
  }
}
