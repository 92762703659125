import axios from 'axios';
import { getStoredUserAuthToken } from './utils';
const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let token = getStoredUserAuthToken();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      };
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem('UserAuth');
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export function fetchMailChimpArchive(query) {
  return axios.get(BASE_URL + '/mailchimp/archive', { query: query });
}

export function fetchPosts(query) {
  return axios
    .post(BASE_URL + '/query', { query: query })
    .then(response => response.data);
}

export function fetchTaxonomy(query) {
  return axios
    .post(BASE_URL + '/taxonomy', { query: query })
    .then(response => response.data)
    .catch(err => console.log(err));
}

export function fetchPost(query) {
  return axios
    .get(BASE_URL + `/${query.type}/${query.id}`, { query: query })
    .then(response => response.data);
}

export function fetchNav(endpoint, id) {
  return axios
    .get(BASE_URL + `/nav/${id}`)
    .then(response => response.data)
    .catch(err => console.log(err.message));
}

export function fetchTags(query, id) {
  return axios
    .post(BASE_URL + `/tags`, query)
    .then(response => response.data)
    .catch(err => console.log(err.message));
}

export function fetchSuggestions(value, filter) {
  return axios.post(BASE_URL + '/search', { query: value, filter: filter });
}

export function fetchSearch(query, page) {
  return axios.post(BASE_URL + '/results', { query: query, page: page });
}

export function fetchTypes() {
  console.log(BASE_URL);
  return axios.get(BASE_URL + '/types');
}

export function fetchLogin(creds) {
  let body = {
    username: creds.username,
    password: creds.password,
  };

  return axios.post(BASE_URL + '/authenticate', body);
}

export function fetchUser() {
  return axios.get(BASE_URL + '/me');
}

export function verifyUser(token) {
  let config = {};
  if (token.access_token) {
    config.headers = { Authorization: 'Bearer ' + token };
  }

  return axios.get(BASE_URL + '/me', config);
}
