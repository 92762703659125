import React, { Component } from 'react';
import PropTypes from 'prop-types';
const styles = {
  active: 'collapse in',
  inactive: 'collapse',
  iconup: 'icon icon-angle-up',
  icondown: 'icon icon-angle-down',
};

export default class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.toggleStatus || false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      active: !this.state.active,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.toggleStatus !== nextProps.toggleStatus) {
      this.setState({
        active: nextProps.toggleStatus,
      });
    }
  }

  renderContent(content) {
    return { __html: this.autoParagraph(content) };
  }

  autoParagraph(text) {
    return '<p>' + text.split(/\n+/).join('</p>\n<p>') + '</p>';
  }

  render() {
    const stateStyle = this.state.active ? styles.active : styles.inactive;
    const iconStyle = this.state.active ? styles.iconup : styles.icondown;
    return (
      <div className="filter-block js--collapse">
        <button className="collapse-header" onClick={this.toggle}>
          <h4 className="content-heading">{this.props.summary}</h4>
          <span className={iconStyle}></span>
        </button>
        <div className={stateStyle} aria-expanded="true">
          <div className="collapse__container">
            <div
              className="page-content__entry"
              dangerouslySetInnerHTML={this.renderContent(this.props.details)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Accordion.propTypes = {
  summary: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};
