import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Archive } from '../../components';
import * as taxonomyActionCreators from '../../reducers/taxonomy';

class ArchiveContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAndHandleTaxonomy({
      taxonomy: this.props.taxonomy,
      id: this.props.id,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.props.fetchAndHandleTaxonomy({
        taxonomy: nextProps.taxonomy,
        id: nextProps.id,
      });
    }
  }
  render() {
    return (
      <Archive
        isFetching={this.props.isFetching}
        error={this.props.error}
        taxonomy={this.props.result}
      />
    );
  }
}

function mapStateToProps({ taxonomy }, props) {
  return {
    isFetching: taxonomy.isFetching,
    error: taxonomy.error,
    result: taxonomy.taxonomy ? taxonomy.taxonomy : {},
    taxonomy: props.match.params.taxonomy,
    id: props.match.params.id,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...taxonomyActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchiveContainer);
