import React from 'react';

export default function Title({ post }) {
  function renderContent(content) {
    return { __html: content };
  }

  return (
    <div className="page-content__header">
      {post.parent > 0 ? (
        <h3
          className="sidebar__title"
          dangerouslySetInnerHTML={renderContent(post.children.parent_title)}
        />
      ) : null}
      <h2
        className="header__title"
        dangerouslySetInnerHTML={renderContent(post.title.rendered)}
      />
    </div>
  );
}
