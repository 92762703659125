import React from 'react';
import { ChildPage } from '../../components';

export default function Children({ children, title }) {
  return (
    <div className="row">
      <div className="section-content">
        <div className="section-content__header">
          <h2 className="header__title">{title}</h2>
        </div>
        <div className="section-content__entry">
          <div className="row">
            {children.pages.map(page => (
              <ChildPage
                parent={children.parent_slug}
                post={page}
                key={page.ID}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
