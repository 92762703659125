import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LogoutContainer } from '../../containers';
import { authContext } from '../../common/AuthContext';
import Authenticate from '../Authentication/Authenticate';

Navigation.propTypes = NavLinks.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

function NavLinks({ menu, isAuthenticated, secondary }) {
  const link = 'nav-link';

  return isAuthenticated === true ? (
    <div
      id="main-nav"
      className="navbar__main-nav collapse navbar-toggleable-sm"
    >
      <ul className="nav-items">
        {menu && menu.items && menu.items.length > 0
          ? menu.items.map(menu =>
              menu.children && menu.children.length > 0 ? (
                <li
                  className="nav-items__item dropdown hidden-md-up"
                  key={menu.object_id}
                >
                  <button
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {menu.title}
                  </button>
                  <ul className="dropdown-menu">
                    {menu.children.map(
                      ({ slug, title, url, object, object_id, type }) => {
                        if (type === 'taxonomy') {
                          return (
                            <li className="nav-items__item" key={object_id}>
                              <Link
                                to={`/archive/${object}/${slug}/${object_id}`}
                              >
                                {title}
                              </Link>
                            </li>
                          );
                        } else if (object === 'custom') {
                          if (
                            url.includes('https://members.analystinstitute.org')
                          ) {
                            return (
                              <li
                                className="nav-items__item mmm"
                                key={object_id}
                              >
                                <Link
                                  to={url.replace(
                                    'https://members.analystinstitute.org',
                                    ''
                                  )}
                                >
                                  {title}
                                </Link>
                              </li>
                            );
                          }
                          return (
                            <li className="nav-items__item" key={object_id}>
                              <a href={url}>{title}</a>
                            </li>
                          );
                        } else {
                          return (
                            <li className="nav-items__item" key={object_id}>
                              <Link
                                to={`/${object}/${menu.slug}/${slug}-${object_id}`}
                              >
                                {title}
                              </Link>
                            </li>
                          );
                        }
                      }
                    )}
                  </ul>
                </li>
              ) : (
                <li
                  className="nav-items__item hidden-sm-up"
                  key={menu.object_id}
                >
                  {menu.object === 'custom' ? (
                    <Link to={menu.url}>
                      <span
                        dangerouslySetInnerHTML={createMarkup(menu.title)}
                        className={link}
                      />
                    </Link>
                  ) : null}
                  {menu.type === 'taxonomy' ? (
                    <Link
                      to={`/archive/${menu.object}/${menu.slug}/${menu.object_id}`}
                      className={link}
                    >
                      <span
                        dangerouslySetInnerHTML={createMarkup(menu.title)}
                      />
                    </Link>
                  ) : null}
                  {menu.type === 'post_type' ? (
                    <Link
                      to={`/${menu.object}/${menu.slug}-${menu.object_id}`}
                      className={link}
                    >
                      <span
                        dangerouslySetInnerHTML={createMarkup(menu.title)}
                      />
                    </Link>
                  ) : null}
                </li>
              )
            )
          : null}
        {secondary &&
          secondary.items.map(item =>
            item.children && item.children.length > 0 ? (
              <li className="nav-items__item dropdown" key={item.id}>
                <button
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {item.title}
                </button>
                <ul className="dropdown-menu">
                  {item.children.map(({ title, url, id }) => {
                    return (
                      <li className="nav-items__item" key={id}>
                        <a
                          href={url}
                          rel="noopener noreferrer"
                          target="_blank"
                          className={link}
                        >
                          {title}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ) : (
              <li className="nav-items__item" key={item.id}>
                <a
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={link}
                >
                  {item.title}
                </a>
              </li>
            )
          )}
        <div className="hidden-md-up">
          <LogoutContainer />
        </div>
      </ul>
    </div>
  ) : (
    <div
      id="main-nav"
      className="navbar__main-nav collapse navbar-toggleable-sm"
    >
      <ul className="nav-items">
        {secondary.items.map(item =>
          item.children && item.children.length > 0 ? (
            <li className="nav-items__item dropdown" key={item.id}>
              <button
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                {item.title}
              </button>
              <ul className="dropdown-menu">
                {item.children.map(({ title, url }) => {
                  return (
                    <li className="nav-items__item">
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={link}
                      >
                        {title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            <li className="nav-items__item">
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={link}
              >
                {item.title}
              </a>
            </li>
          )
        )}
        {!isAuthenticated ? <Authenticate /> : null}
      </ul>
    </div>
  );
}

function SubNavLinks({ menu, isAuthenticated }) {
  const link = 'nav-link';
  return isAuthenticated ? (
    <ul className="nav-items">
      {menu.items && menu.items.length > 0
        ? menu.items.map(menu =>
            menu.children && menu.children.length > 0 ? (
              <li className="nav-items__item dropdown" key={menu.object_id}>
                <button
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  {menu.title}
                </button>
                <ul className="dropdown-menu">
                  {menu.children.map(
                    ({ slug, title, url, object, object_id, type }) => {
                      if (type === 'taxonomy') {
                        return (
                          <li className="nav-items__item" key={object_id}>
                            <Link
                              to={`/archive/${object}/${slug}/${object_id}`}
                            >
                              {title}
                            </Link>
                          </li>
                        );
                      } else if (object === 'custom') {
                        if (
                          url.includes('https://members.analystinstitute.org')
                        ) {
                          return (
                            <li className="nav-items__item mmm" key={object_id}>
                              <Link
                                to={url.replace(
                                  'https://members.analystinstitute.org',
                                  ''
                                )}
                              >
                                {title}
                              </Link>
                            </li>
                          );
                        }
                        return (
                          <li className="nav-items__item" key={object_id}>
                            <a href={url}>{title}</a>
                          </li>
                        );
                      } else {
                        return (
                          <li className="nav-items__item" key={object_id}>
                            <Link
                              to={`/${object}/${menu.slug}/${slug}-${object_id}`}
                            >
                              {title}
                            </Link>
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </li>
            ) : (
              <li className="nav-items__item" key={menu.object_id}>
                {menu.object === 'custom' ? (
                  menu.url.includes('https://members.analystinstitute.org') ? (
                    <Link
                      to={menu.url.replace(
                        'https://members.analystinstitute.org',
                        ''
                      )}
                    >
                      {menu.title}
                    </Link>
                  ) : (
                    <a class="custom nonmember" href={menu.url}>
                      {menu.title}
                    </a>
                  )
                ) : null}

                {menu.type === 'taxonomy' ? (
                  <Link
                    to={`/archive/${menu.object}/${menu.slug}/${menu.object_id}`}
                    className={link}
                  >
                    <span dangerouslySetInnerHTML={createMarkup(menu.title)} />
                  </Link>
                ) : null}
                {menu.type === 'post_type' ? (
                  <Link
                    to={`/${menu.object}/${menu.slug}-${menu.object_id}`}
                    className={link}
                  >
                    <span dangerouslySetInnerHTML={createMarkup(menu.title)} />
                  </Link>
                ) : null}
              </li>
            )
          )
        : null}
      <LogoutContainer />
    </ul>
  ) : null;
}

function createMarkup(title) {
  return { __html: title };
}

export default function Navigation({ menu, main, secondary }) {
  const { auth } = React.useContext(authContext);

  return (
    <div>
      <nav className="navbar navbar--main">
        <div className="container">
          <div className="navbar-logo">
            <Link to="/">
              <img
                className="logo--text"
                data-pin-no-hover="true"
                src="https://analysts-ppm.s3.amazonaws.com/uploads/2020/01/ai-fulllogo-white.png"
                alt="Analyst Institute"
              />
            </Link>
            <button
              className="navbar-toggler hidden-md-up collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#main-nav"
            >
              <span className="icon icon-menu"></span>
            </button>
          </div>
          {!menu.isFetching && menu[main] && menu[secondary] ? (
            <NavLinks
              menu={menu[main]}
              secondary={menu[secondary]}
              isAuthenticated={!!auth}
            />
          ) : null}
        </div>
      </nav>
      <nav className="navbar navbar--secondary">
        <div className="container">
          <div id="secondary-nav" className="navbar__secondary-nav">
            {!menu.isFetching && menu[main] ? (
              <SubNavLinks menu={menu[main]} isAuthenticated={!!auth} />
            ) : null}
          </div>
        </div>
      </nav>
    </div>
  );
}
