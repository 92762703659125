import React from 'react';
import { formatTimestamp } from '../../helpers/utils';

export default function FeedPost(props) {
  const post = props.post;

  return (
    <div className="col-article">
      <div className="css--article">
        <div className="block-article__content">
          <div className="block-article__header">
            <h4 className="block-article__header--title">
              {post.settings.subject_line}
            </h4>
            <p className="block-article__header--date">
              {formatTimestamp(post.send_time)}
            </p>
          </div>
          <span className="block-article__btn">
            <span className="icon icon-chevron-circle-right"></span>
            <span className="btn-link__label">View Digest</span>
          </span>
          <a
            href={post.archive_url}
            className="block-article__link"
            target="_blank"
            title="Archive Url"
            rel="noopener noreferrer"
          ></a>
        </div>
      </div>
    </div>
  );
}
