import React from 'react';

export default function Types(props) {
  let extras = [];

  if (
    props.type === 'qna' ||
    props.type === 'skills' ||
    props.type === 'digest'
  ) {
    extras = [];
  } else if (props.type === 'research') {
    props.types.map(({ name, slug, id }) =>
      extras.push(
        <option key={id} value={id}>
          {name}
        </option>
      )
    );
  } else if (props.type === 'all') {
    props.types.map(({ name, slug, id }) =>
      extras.push(
        <option key={id} value={id}>
          {name}
        </option>
      )
    );
    extras.push(
      <option value="qna" key="qna">
        Q &amp; A
      </option>
    );
    extras.push(
      <option value="skills" key="skills">
        Skills
      </option>
    );
    extras.push(
      <option value="digest" key="digest">
        Digest
      </option>
    );
  }

  return extras.length > 0 ? (
    <select
      onChange={props.change}
      className="form-control c-select input--types"
    >
      <option value="all">All Content</option>
      {extras}
    </select>
  ) : null;
}
