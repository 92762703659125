import React from 'react';
import { SearchBox } from '../../containers';
import Loader from '../Loader';
import { InstantSearch, Configure, Pagination } from 'react-instantsearch-dom';
import { searchClient } from '../../containers/Algolia/SearchContainer';
import { CustomHits, VirtualMenu } from '../../containers/Algolia/hitComps';
import { Helmet } from 'react-helmet';
const types = {
  type: 'terms.name',
  post_tag: 'tags',
};

export default function Archive({ taxonomy, isFetching }) {
  return (
    <div className="section-wrapper">
      <div className="container">
        <div className="row">
          <div className="section-content">
            {isFetching === true ? (
              <Loader />
            ) : (
              <div>
                <Helmet>
                  <title>
                    {`Archive: ${taxonomy.name}`} - Analyst Institute
                  </title>
                </Helmet>
                <SearchBox
                  taxonomy={taxonomy}
                  title="Archive"
                  heading={taxonomy.name}
                  placeholder={`Search all '${taxonomy.name.toLowerCase()}' documents...`}
                />
                <div className="section-content__entry">
                  <InstantSearch
                    searchClient={searchClient}
                    indexName={'research_date_desc'}
                  >
                    <Configure hitsPerPage={6} />
                    {taxonomy ? (
                      <VirtualMenu
                        attribute={types[taxonomy.taxonomy]}
                        defaultRefinement={taxonomy.name}
                      />
                    ) : null}
                    <CustomHits index={'research'} />
                    <Pagination />
                  </InstantSearch>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
