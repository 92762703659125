import React from 'react';
import { formatTimestamp, typeColor } from '../../helpers/utils';
import { Link } from 'react-router-dom';

export default function SearchResult(props) {
  const result = props.post;

  function renderContent(content) {
    return { __html: content };
  }

  function objectTypeLabel(type) {
    if (type === 'qna') {
      return 'Q&A';
    } else if (type === 'digest') {
      return 'Digest';
    } else if (type === 'skills') {
      return 'Skills';
    } else {
      return 'Research';
    }
  }

  return (
    <div className="col-article">
      <div className={typeColor(result.object_type, result.terms)}>
        <div className="block__meta">
          <div className="meta-list-container">
            <ul className="meta-list">
              {result.terms && result.terms.length > 0 ? (
                result.terms.map(({ name, term_id, taxonomy, slug }) => {
                  let itemType = 'meta-list__item--type';
                  taxonomy === 'post_tag'
                    ? (itemType = 'meta-list__item--tag')
                    : (itemType = 'meta-list__item--type');
                  if (term_id !== 5) {
                    return (
                      <li className={itemType} key={term_id}>
                        <Link
                          to={`/archive/${taxonomy}/${slug}/${term_id}`}
                          className="meta-list__item--link"
                          dangerouslySetInnerHTML={renderContent(name)}
                        />
                      </li>
                    );
                  }

                  return null;
                })
              ) : (
                <li className="meta-list__item--type">
                  <span className="meta-list__item--link">
                    {/* {result.object_type === 'qna' ? 'Q&A' : result.object_type } */}
                    {objectTypeLabel(result.object_type)}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="block-article__content">
          <div className="block-article__header">
            <h4
              className="block-article__header--title"
              dangerouslySetInnerHTML={renderContent(result.title)}
            />
            <p className="block-article__header--date">
              {formatTimestamp(result.date)}
            </p>
          </div>
          <div
            className="block-article__text"
            dangerouslySetInnerHTML={renderContent(result.excerpt)}
          />
          <span className="block-article__btn">
            <span className="icon icon-chevron-circle-right"></span>
            <span className="btn-link__label">Read More</span>
          </span>
          <Link
            to={`/${result.object_type}/${result.slug}-${result.objectID}`}
            className="block-article__link"
          ></Link>
        </div>
      </div>
    </div>
  );
}
