import { fetchSearch } from '../helpers/api'

const FETCHING_SEARCH = 'FETCHING_SEARCH'
const FETCHING_SEARCH_ERROR = 'FETCHING_SEARCH_ERROR'
const FETCHING_SEARCH_SUCCESS = 'FETCHING_SEARCH_SUCCESS'
const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE';


function fetchingSearch () {
  return {
    type: FETCHING_SEARCH,
  }
}

function fetchingSearchError (error) {
  return {
    type: FETCHING_SEARCH_ERROR,
    error: 'Error fetching posts',
  }
}

function fetchingSearchSuccess (posts,page) {
  return {
    type: FETCHING_SEARCH_SUCCESS,
    posts,
  }
}

function updateFilterQuery(filter,page) {
  return {
    type: UPDATE_FILTER_VALUE,
    filter,
    page
  };
}

export function setFilter(filter,page) {
  return function (dispatch,store) {
    dispatch(updateFilterQuery(filter,page));

    dispatch(fetchingSearch())

    fetchSearch(filter,page,false)
      .then((posts) =>
        dispatch(fetchingSearchSuccess(posts.data))
      )
      .catch((error) => dispatch(fetchingSearchError(error)))
  }
}

export function fetchAndHandleSearch (query,page) {
  return function (dispatch) {
    dispatch(fetchingSearch())

    fetchSearch(query,page,false)
      .then((posts) =>
        dispatch(fetchingSearchSuccess(posts.data))
      )
      .catch((error) => dispatch(fetchingSearchError(error)))
  }
}

const initialState = {
  isFetching: true,
  error: '',
  filter: {},
  page: 1
}

export default function search (state = initialState, action) {
  switch (action.type) {
    case FETCHING_SEARCH :
      return {
        ...state,
        isFetching: true,
      }
    case FETCHING_SEARCH_ERROR :
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case FETCHING_SEARCH_SUCCESS :
      return {
        ...state,
        isFetching: false,
        error: '',
        posts: action.posts
      }
    case UPDATE_FILTER_VALUE:
      return {
        ...state,
        filter: action.filter,
        page: action.page
      };
    default :
      return state
  }
}