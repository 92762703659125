import React from 'react';
import { SearchBox, RecommendedContainer } from '../../containers';

class HomeContainer extends React.Component {
  render() {
    return (
      <div className="section-wrapper">
        <div className="container">
          <SearchBox />
          <RecommendedContainer tag="recommended-home" />
        </div>
      </div>
    );
  }
}

export default HomeContainer;
