import moment from 'moment';

export function formatTimestamp(timestamp) {
  return moment(timestamp).format('MMMM YYYY');
}

export function typeColor(type, terms, classBlock = 'block-article') {
  let colorClass = classBlock;

  if (type === 'qna') {
    colorClass += ' css--qna';
  }
  if (type === 'skills') {
    colorClass += ' css--skill';
  }
  if (type === 'digest') {
    colorClass += ' css--digest';
  }
  if (type === 'research' && terms && terms.length > 0) {
    switch (terms[0].slug) {
      case 'case-study':
        colorClass += ' css--cs';
        break;
      case 'presentation':
        colorClass += ' css--pres';
        break;
      case 'report':
        colorClass += ' css--report';
        break;
      default:
      case 'article':
        colorClass += ' css--article';
        break;
    }
  }

  return colorClass;
}

export const postTypes = {
  research: 'Research',
  qna: 'Q&A',
  skills: 'Skills',
  digest: 'Digest',
  archive: 'Archive',
};

/** Return user auth from local storage value */
export const getStoredUserAuth = () => {
  const auth = window.localStorage.getItem('UserAuth');
  if (auth) {
    return JSON.parse(auth);
  }
  return null;
};

export const getStoredUserAuthToken = () => {
  const auth = window.localStorage.getItem('UserAuth');
  if (auth) {
    const info = JSON.parse(auth);

    return info.access_token;
  }
  return null;
};
