import { fetchTags } from '../helpers/api';

const FETCHING_TAGS = 'FETCHING_TAGS';
const FETCHING_TAGS_ERROR = 'FETCHING_TAGS_ERROR';
const FETCHING_TAGS_SUCCESS = 'FETCHING_TAGS_SUCCESS';

function fetchingTags() {
  return {
    type: FETCHING_TAGS,
  };
}

function fetchingTagsError(error) {
  return {
    type: FETCHING_TAGS_ERROR,
    error: 'Error fetching tags',
  };
}

function fetchingTagsSuccess(tags) {
  return {
    type: FETCHING_TAGS_SUCCESS,
    tags,
  };
}

export function fetchAndHandleTags(query) {
  return function(dispatch) {
    dispatch(fetchingTags());

    fetchTags(query, false)
      .then(tags => {
        dispatch(fetchingTagsSuccess(tags));
      })
      .catch(error => dispatch(fetchingTagsError(error)));
  };
}

const initialState = {
  isFetching: true,
  error: '',
};

export default function tags(state = initialState, action) {
  switch (action.type) {
    case FETCHING_TAGS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCHING_TAGS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCHING_TAGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        tags: action.tags,
      };
    default:
      return state;
  }
}
