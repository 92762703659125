import React, { useEffect } from 'react';
import {
  DigestContainer,
  MainContainer,
  HomeContainer,
  SingleContainer,
  qnaContainer,
  researchContainer,
  archiveContainer,
  skillsContainer,
  PermissionsContainer,
  digestsContainer,
} from '../containers';
//import Permissions from 'components'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SearchContainer from '../containers/Algolia/SearchContainer';
import { AccessContainer } from '../containers/Main/MainContainer';
import TagManager from 'react-gtm-module';

const Application = props => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayerName: 'TitlePath',
      event: 'PageView',
      dataLayer: {
        title: document.title,
        path: window.location.pathname,
      },
    });
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/entry/:access_token" component={AccessContainer} />
        <MainContainer>
          <Switch>
            <Route exact path="/" component={HomeContainer} />
            <Route exact path="/search" component={SearchContainer} />
            <Route exact path="/results" component={SearchContainer} />
            <Route exact path="/qna" component={qnaContainer} />
            <Route exact path="/skills" component={skillsContainer} />
            <Route exact path="/research" component={researchContainer} />
            <Route exact path="/digest" component={DigestContainer} />
            <Route exact path="/digests" component={digestsContainer} />
            <Route
              path="/archive/:taxonomy/:slug/:id"
              component={archiveContainer}
            />

            <Route
              path="/:type/:parent/:slug-:id"
              component={SingleContainer}
            />
            <Route path="/:type/:slug-:id" component={SingleContainer} />
            <Route path="/permissions" component={PermissionsContainer} />
          </Switch>
        </MainContainer>
      </Switch>
    </Router>
  );
};

export default Application;
