import React from 'react';
import { Link } from 'react-router-dom';

export default function Tags({ post }) {
  function renderContent(content) {
    return { __html: content };
  }

  return (
    <div className="sidebar__meta">
      <div className="meta-list-container">
        <ul className="meta-list">
          {post.terms.length > 0
            ? post.terms.map(({ name, term_id, taxonomy, slug }) => {
                let itemType = 'meta-list__item--type';
                if (taxonomy === 'post_tag') {
                  itemType = 'meta-list__item--tag';
                }
                if (term_id !== 5) {
                  return (
                    <li className={itemType} key={term_id}>
                      <Link
                        to={`/archive/${taxonomy}/${slug}/${term_id}`}
                        className="meta-list__item--link"
                        dangerouslySetInnerHTML={renderContent(name)}
                      />
                    </li>
                  );
                }

                return null;
              })
            : null}
        </ul>
        <ul className="meta-list">
          {post.type === 'research'
            ? post.tags.map(({ name, term_id, taxonomy, slug }) => (
                <li className="meta-list__item--tag" key={term_id}>
                  <Link
                    to={`/archive/${taxonomy}/${slug}/${term_id}`}
                    className="meta-list__item--link"
                  >
                    {name}
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  );
}
