import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Single, Page } from '../../components';
import * as postsActionCreators from '../../reducers/posts';
import { SearchBox } from '../../containers';
import Loader from '../../components/Loader';

class SingleContainer extends React.Component {
  componentDidMount() {
    this.props.fetchAndHandlePost({
      id: this.props.entry,
      type: this.props.type || 'pages',
    });
  }
  componentWillReceiveProps(nextProps) {
    let type = this.props.type || 'pages';

    if (this.props.entry !== nextProps.entry) {
      if (typeof nextProps.type === 'undefined') {
        type = 'pages';
      } else {
        type = nextProps.type;
      }
      this.props.fetchAndHandlePost({ id: nextProps.entry, type: type });
    }
  }
  setToggle = e => {
    e.preventDefault();
    this.props.toggleAll(!this.props.toggle);
  };
  render() {
    let template;

    if (this.props.error) {
      return (
        <div className="section-wrapper">
          <div className="container">
            <div className="alert alert-danger page-content">
              {this.props.error.message}
            </div>
          </div>
        </div>
      );
    }

    if (this.props.isFetching) {
      template = <Loader />;
    } else {
      switch (this.props.post.type) {
        case 'page':
          template = (
            <Page
              isFetching={this.props.isFetching}
              toggleAll={this.setToggle}
              error={this.props.error}
              post={this.props.post}
              toggle={this.props.toggle}
            />
          );
          break;
        default:
          template = (
            <Single
              isFetching={this.props.isFetching}
              toggleAll={this.setToggle}
              error={this.props.error}
              post={this.props.post}
              toggle={this.props.toggle}
            />
          );
      }
    }

    return (
      <div className="section-wrapper">
        <div className="container">
          {template}
          {!this.props.isFetching &&
          this.props.showSearch &&
          (this.props.type === 'research' ||
            this.props.type === 'qna' ||
            this.props.type === 'digest' ||
            this.props.type === 'skills') ? (
            <SearchBox />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  var parts = props.match.params.id.split('-');
  return {
    isFetching: state.posts.isFetching || false,
    error: state.posts.error,
    toggle: state.posts.toggle,
    showSearch: state.posts.showSearch,
    post: state.posts.post ? state.posts.post : {},
    entry: parts.pop(),
    type: props.match.params.type,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...postsActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleContainer);
