import React, { useState, useEffect, createRef } from 'react';
import { InstantSearch, Hits } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { Root, HitsWrapper } from './styles';
import { withRouter } from 'react-router';
import * as hitComps from './hitComps';
import { VirtualMenu } from './hitComps';
import CustomSearchBox from './input';
import qs from 'qs';
const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler();
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside);
    };
  });
};

const types = {
  type: 'terms.name',
  post_tag: 'tags',
};

const AutocompleteContainer = ({
  collapse,
  hitsAsGrid,
  virtual = [],
  filter = {},
  ...props
}) => {
  const ref = createRef();
  const [query, setQuery] = useState({ query: '' });
  const [focus, setFocus] = useState(false);

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );

  useClickOutside(ref, () => setFocus(false));

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={'research'}
      searchState={query}
      onSearchStateChange={search => {
        setQuery({ ...query, ...search });
      }}
      root={{ Root, props: { ref } }}
    >
      {virtual
        ? virtual.map(v => (
            <VirtualMenu
              attribute={v.key}
              key={v.key}
              defaultRefinement={v.value}
            />
          ))
        : null}
      {filter
        ? Object.keys(filter).map(v => (
            <VirtualMenu
              attribute={types[v] || v}
              key={v}
              defaultRefinement={filter[v]}
            />
          ))
        : null}
      <CustomSearchBox
        onFocus={() => setFocus(true)}
        onSubmit={e => {
          e.preventDefault();
          let search = {};
          if (filter) {
            search.refinementList = {};
            Object.keys(filter).forEach(item => {
              search.refinementList[types[item]] = [filter[item]];
            });
          }

          if (query.query) {
            search.query = query.query;
          }

          props.history.push(`/search?${qs.stringify(search)}`);
        }}
        {...{ collapse, focus }}
        renderOverlay={() => {
          return (
            <HitsWrapper
              show={query && query.query.length > 0 && focus}
              asGrid={hitsAsGrid}
            >
              <Hits hitComponent={hitComps['SuggestionHit']} />
            </HitsWrapper>
          );
        }}
      />
    </InstantSearch>
  );
};

export default withRouter(AutocompleteContainer);
