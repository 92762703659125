import React from 'react';
import { Entry, Title, Meta, PubDate, Children } from '../../components';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

export default function Page({ post, isFetching, error }) {
  let toggle;
  let toggleAll;

  if (post.redirection) {
    return (
      <Redirect
        to={post.redirection.replace(
          'https://members.analystinstitute.org',
          ''
        )}
      />
    );
  }
  return (
    <>
      <Helmet>
        <title>{post.title.rendered} - Analyst Institute</title>
      </Helmet>
      <div className="section-wrapper">
        <div className="content-row"></div>
        <div className="content-row">
          <main id="" className="page-content">
            {post.title ? <Title post={post} /> : null}
            {post.content ? <Entry post={post} /> : null}
          </main>
          <aside className="page-sidebar">
            <div className="sidebar__header">
              <h3 className="sidebar__title">More About This Article</h3>
            </div>

            <ul className="sidebar-infos">
              {post.authors && post.authors.length > 0 ? (
                <Meta title={'Authors'} meta={post.authors} />
              ) : null}

              {post.date ? (
                <PubDate title={'Published Date'} date={post.date} />
              ) : null}
            </ul>

            <ul className="sidebar-actions">
              {post.subpages && post.subpages.length > 0 ? (
                <li className="sidebar-action">
                  <span
                    className={
                      toggle ? 'icon icon-collapse' : 'icon icon-expand'
                    }
                  ></span>
                  <span className="sidebar-action__title">
                    {toggle ? 'Collapse All' : 'Expand All'}
                  </span>
                  <span
                    onClick={e => toggleAll(e)}
                    className="sidebar-item--link"
                  ></span>
                </li>
              ) : null}
            </ul>
          </aside>
        </div>

        {post.children.pages ? (
          <Children
            title={`Other ${post.children.parent_title}`}
            children={post.children}
          />
        ) : null}

        {error ? <p>{error}</p> : null}
      </div>
    </>
  );
}
