import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AuthenticateForm from './AuthenticateForm';

const Authenticate = () => {
  const [showLogin, setLogin] = useState(false);

  return (
    <li
      className={`nav-items__item nav-item dropdown menu-item--login ${
        showLogin ? 'open' : ''
      }`}
    >
      <span
        data-toggle="dropdown"
        className="nav-link"
        aria-expanded="true"
        onClick={() => setLogin(!showLogin)}
      >
        Log In
      </span>

      <div className="dropdown-menu dropdown-menu--login">
        <div className="login-box">
          <button
            data-toggle="dropdown"
            className="login-box__close hidden-md-up"
          >
            <span className="icon icon-close"></span> Close
          </button>
          <AuthenticateForm />
        </div>
      </div>
    </li>
  );
};

Authenticate.propTypes = {
  error: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onAuth: PropTypes.func.isRequired,
};

export default Authenticate;
