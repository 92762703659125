import { fetchTaxonomy } from '../helpers/api';

const FETCH_TAXONOMY = 'FETCH_TAXONOMY';
const FETCH_TAXONOMY_ERROR = 'FETCH_TAXONOMY_ERROR';
const FETCH_TAXONOMY_SUCCESS = 'FETCH_TAXONOMY_SUCCESS';

function fetchingTaxonomy() {
  return {
    type: FETCH_TAXONOMY,
  };
}

function fetchingTaxonomyError(error) {
  return {
    type: FETCH_TAXONOMY_ERROR,
    error: error,
  };
}

function fetchingTaxonomySuccess(taxonomy) {
  return {
    type: FETCH_TAXONOMY_SUCCESS,
    taxonomy,
  };
}

export function fetchAndHandleTaxonomy(query) {
  return function(dispatch) {
    dispatch(fetchingTaxonomy());
    fetchTaxonomy(query, false)
      .then(taxonomy => dispatch(fetchingTaxonomySuccess(taxonomy)))
      .catch(error => dispatch(fetchingTaxonomyError(error)));
  };
}

const initialState = {
  isFetching: true,
  error: '',
};

export default function taxonomy(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAXONOMY:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TAXONOMY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case FETCH_TAXONOMY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: '',
        taxonomy: action.taxonomy,
      };
    default:
      return state;
  }
}
