import React from 'react';
import { SearchBox, RecommendedContainer } from '../../containers';

const SkillsContainer = () => {
  return (
    <div className="section-wrapper">
      <div className="container">
        <SearchBox
          getTags="true"
          title="Skills"
          heading="What Skills are you searching for?"
          object="skills"
          placeholder="Search Skills..."
          virtual={[{ key: 'object_type', value: 'skills' }]}
        />
        <RecommendedContainer tag="recommended" type="skills" />
      </div>
    </div>
  );
};

export default SkillsContainer;
