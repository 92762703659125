import React from 'react';
import Authenticate from '../Authentication/Authenticate';
import AuthenticateForm from '../Authentication/AuthenticateForm';

export default function Members() {
  const imgUrl =
    'https://analystinstitute.org/wp-content/uploads/2016/05/bg.jpg';
  const divStyle = {
    backgroundImage: 'url(' + imgUrl + ')',
    backgroundSize: 'cover',
  };

  return (
    <div className="section-wrapper" style={divStyle}>
      <div className="container">
        <main className="row page-content">
          <div className="page-content__header" style={{ maxWidth: '70%' }}>
            <p className="lead">
              You attempted to reach a members-only page. <br />
              Please log in or apply to join (below).
            </p>
            <p>
              Analyst Institute collaborates with progressive organizations and
              campaigns around the country to measure and increase the impact of
              their programs.
            </p>
            <a className="btn-link" href="https://analystinstitute.org/join/">
              <span className="icon icon-chevron-circle-right"></span>
              <span className="btn-link__label"> Apply Now</span>
            </a>
            <a className="btn-link" href="https://analystinstitute.org/">
              <span className="icon icon-chevron-circle-right"></span>
              <span className="btn-link__label"> Back to homepage</span>
            </a>
          </div>
          <div className="login-box">
            <AuthenticateForm />
          </div>
        </main>
      </div>
    </div>
  );
}
