import React from 'react';
import { FeedDigestPost } from '../../components';

export default function FeedDigest(props) {
  return (
    <div className="row">
      <div className="section-content">
        <div className="section-content__header">
          <h2 className="header__title">{props.title}</h2>
        </div>
        <div className="section-content__entry">
          <div className="row">
            {props.posts.map(post => (
              <FeedDigestPost post={post} key={post.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
