import React from 'react';
import { FeedDigest } from '../../components';
import { fetchMailChimpArchive } from '../../helpers/api';
import Loader from '../../components/Loader';

class DigestContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      archive: [],
      error: null,
    };
  }

  componentWillMount() {
    var that = this;

    fetchMailChimpArchive({})
      .then(function(response) {
        return response.data;
      })
      .then(function(result) {
        that.setState({ archive: result.campaigns || [], loading: false });
      })
      .catch(function(err) {
        that.setState({
          error: 'Unable to fetch newsletter archive',
          loading: false,
        });
      });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="section-wrapper">
          <div className="container">
            <div className="alert alert-danger page-content">
              {this.props.error.message}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="container">
        <FeedDigest
          posts={this.state.archive}
          title="Analyst Group Digest Archive"
        />
      </div>
    );
  }
}

export default DigestContainer;
