import React from 'react';
import PropTypes from 'prop-types';
import PDFObject from 'pdfobject';
import { getStoredUserAuthToken } from '../../helpers/utils';

require('pdfjs-dist/build/pdf.combined');
require('pdfjs-dist/web/compatibility');

export default function Entry({ post }) {
  function renderContent() {
    return { __html: post.content.rendered };
  }
  return (
    <div className="page-content__entry">
      <div dangerouslySetInnerHTML={renderContent()} />
      {post.document ? (
        <div className="content-document">
          <PdfViewer post={post} />
        </div>
      ) : null}
    </div>
  );
}

class PdfViewer extends React.Component {
  componentDidMount() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    console.log(BASE_URL, 'REACT_APP_BASE_URL');
    let token = getStoredUserAuthToken();
    const { containerId, post } = this.props;

    var options = {
      pdfOpenParams: {
        toolbar: 0,
      },
      forcePDFJS: true,
      PDFJS_URL: '/pdfjs/web/viewer.html',
    };

    const url =
      BASE_URL + '/pdf/' + post.type + '/' + post.id + '?access_token=' + token;

    PDFObject.embed(url, `#${containerId}`, options);
  }

  render() {
    const { width, height, containerId } = this.props;

    return <div style={{ width, height }} id={containerId} />;
  }
}

PdfViewer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  containerId: PropTypes.string,
};

PdfViewer.defaultProps = {
  width: '100%',
  height: '600px',
  containerId: 'pdf-viewer',
};
