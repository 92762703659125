import { fetchSuggestions,fetchTypes } from '../helpers/api'
import { fetchingPostsError } from './posts';

const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
const MAYBE_UPDATE_SUGGESTIONS = 'MAYBE_UPDATE_SUGGESTIONS';
const LOAD_SUGGESTIONS_BEGIN = 'LOAD_SUGGESTIONS_BEGIN';
const UPDATE_FILTER_VALUE = 'UPDATE_FILTER_VALUE';
const LOAD_TYPES = 'LOAD_TYPES';

function loadSuggestions(value,filter) {
  return dispatch => {
    dispatch(loadSuggestionsBegin());
    fetchSuggestions(value,filter,false)
      .then((suggestions) =>
        dispatch(maybeUpdateSuggestions(suggestions.data,value))
      )
      .catch((error) => dispatch(fetchingPostsError(error)))
  };
}

function loadTypes(value,filter) {
  return dispatch => {

    fetchTypes(false)
      .then((types) =>
        dispatch(loadSuggestionTypes(types.data))
      )
      .catch((error) => dispatch(fetchingPostsError(error)))
  };
}

function updateInputValue(value) {
  return {
    type: UPDATE_INPUT_VALUE,
    value
  };
}

function updateFilterQuery(filter) {
  return {
    type: UPDATE_FILTER_VALUE,
    filter
  };
}

function loadSuggestionTypes(types) {
  return {
    type: LOAD_TYPES,
    types
  };
}

function clearSuggestions() {
  return {
    type: CLEAR_SUGGESTIONS
  };
}

function loadSuggestionsBegin() {
  return {
    type: LOAD_SUGGESTIONS_BEGIN
  };
}

function maybeUpdateSuggestions(suggestions, value) {
  return {
    type: MAYBE_UPDATE_SUGGESTIONS,
    suggestions,
    value
  };
}

export function resetFilter(filter) {
  return function (dispatch) {
    dispatch(updateFilterQuery({}));
    dispatch(updateInputValue(""));
    dispatch(clearSuggestions({}));

  }
}

export function resetValue(filter) {
  return function (dispatch) {

    dispatch(updateInputValue(""));
    dispatch(clearSuggestions({}));


  }
}

export function setFilter(filter) {
  return function (dispatch) {
    dispatch(updateFilterQuery(filter));
  }
}

export function onChange(event, { newValue } ) {
  return function (dispatch) {
    dispatch(updateInputValue(newValue));
    const value = newValue.trim();

    if (value === '') {
      dispatch(clearSuggestions());
    }
  }
}

export function onSuggestionsUpdateRequested({value}) {
  return function (dispatch,store) {
    dispatch(loadSuggestions(value,store().suggest.filter));
  }
}

export function getSuggestionsTypes() {
  return function (dispatch) {
    dispatch(loadTypes());
  }
}

/* ------------------- */
/*    Redux reducer    */
/* ------------------- */

const initialState = {
  value: '',
  suggestions: [],
  isLoading: false,
  filter: {},
  types: []
};

export default function suggestions(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_INPUT_VALUE:
      return {
        ...state,
        value: action.value
      };

    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: []
      };

    case LOAD_SUGGESTIONS_BEGIN:
      return {
        ...state,
        isLoading: true
      };

    case MAYBE_UPDATE_SUGGESTIONS:
      if (action.value !== state.value) {
        return {
          ...state,
          isLoading: false
        };
      }
      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false
      };
    case UPDATE_FILTER_VALUE:
      return {
        ...state,
        filter: action.filter
      };
    case LOAD_TYPES:
      return {
        ...state,
        types:action.types
      }
    default:
      return state;
  }
}