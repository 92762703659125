import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
const CustomSearchBox = connectSearchBox(
  ({ refine, renderOverlay, onSubmit, currentRefinement, ...rest }) => {
    return (
      <form className="sk-search-box" onSubmit={onSubmit}>
        <div className="sk-search-box__icon"></div>
        <div className="react-autosuggest__container">
          <input
            type="text"
            onChange={e => refine(e.target.value)}
            className="react-autosuggest__input"
            placeholder="Enter search term..."
            value={currentRefinement}
            {...rest}
          />
          {renderOverlay ? renderOverlay() : null}
        </div>
        <button
          class="sk-search-box__action"
          type={onSubmit ? 'submit' : 'button'}
        >
          Search
        </button>
      </form>
    );
  }
);

export default CustomSearchBox;
