import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as searchActionCreators from '../../reducers/search';
import * as suggestActionCreators from '../../reducers/suggest';
import { Hits } from '../../components';
import { Autosuggest } from '../../containers';

class SearchQueryContainer extends React.Component {
  componentDidMount() {
    let filter = {
      sortBy: 'rel',
    };
    _.map(this.props.query, (item, key) => {
      if (key === 'q') {
        filter.q = item;
      } else {
        if (filter[key]) {
          filter[key].push(item);
        } else {
          filter[key] = [item];
        }
      }
    });
    this.props.setFilter(filter, this.props.page);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      let filter = {
        sortBy: 'rel',
      };
      _.map(nextProps.query, (item, key) => {
        if (key === 'q') {
          filter.q = item;
        } else {
          if (filter[key]) {
            filter[key].push(item);
          } else {
            filter[key] = [item];
          }
        }
      });
      this.props.resetValue();
      this.props.setFilter(filter, this.props.page);
    }
  }
  updateFilter(item) {
    let filter = this.props.filter;
    let key = '';

    switch (item.type) {
      case 'tags':
        key = 'post_tag';
        break;
      case 'authors':
        key = 'authors';
        break;
      case 'organizations':
        key = 'organizations';
        break;
      case 'format':
        key = 'type_name';
        break;
      case 'types':
        key = 'object';
        break;
      case 'year':
        key = 'year';
        break;
      default:
        key = '';
    }

    if (item.sort) {
      filter.sortBy = item.sort;
      this.props.setFilter(filter, 1);
    } else {
      if (key !== '') {
        if (filter[key]) {
          const index = filter[key].indexOf(item.value);
          if (index > -1) {
            filter[key].splice(index, 1);
          } else {
            filter[key].push(item.value);
          }
        } else {
          filter[key] = [item.value];
        }
        this.props.setFilter(filter, 1);
      }
    }
  }
  nextPage() {
    let filter = this.props.filter;
    let page = this.props.page + 1;
    this.props.setFilter(filter, page);
  }
  prevPage() {
    let filter = this.props.filter;
    let page = this.props.page - 1;
    if (page <= 0) page = 1;
    this.props.setFilter(filter, page);
  }
  clearSearch(e) {
    e.preventDefault();
    let filter = {};
    this.props.query.q = '';
    this.props.resetValue();
    this.props.setFilter(filter, 1);
  }
  render() {
    var linkStyle = {
      position: 'relative',
      zIndex: 9999,
      fontSize: '.6rem',
      padding: '.5rem',
    };
    return (
      <div className="section-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="content-row">
                <div className="search-container">
                  <div className="search-wrapper">
                    <div className="search__header">
                      {this.props.results.hits ? (
                        <h1 className="search__sub-title">
                          Results {(this.props.page - 1) * 12 + 1} -{' '}
                          {this.props.page * 12 <= this.props.results.hits.total
                            ? this.props.page * 12
                            : this.props.results.hits.total}{' '}
                          of {this.props.results.hits.total}{' '}
                        </h1>
                      ) : null}

                      {this.props.query.q ? (
                        <h2 className="search__title">
                          "{this.props.query.q}"
                        </h2>
                      ) : null}
                    </div>
                    <div className="sk-search-box">
                      <Autosuggest
                        options={this.props.filter}
                        placeholder={this.props.placeholder}
                      />
                    </div>
                    <span
                      style={linkStyle}
                      onClick={e => this.clearSearch(e)}
                      className="small text-danger"
                    >
                      Clear Search
                    </span>
                    <small>
                      <strong>Disclaimer:</strong> The material housed in this
                      password-protected website is confidential and only
                      intended to be viewed and utilized by members of the
                      Analyst Group. Please refrain from sharing any material
                      outside of the Group without explicit permission.
                      Maintaining confidentiality is essential to ensure that
                      partners and Analyst Group members are comfortable sharing
                      their findings with the community.
                    </small>
                  </div>
                </div>
              </div>

              <Hits
                isFetching={this.props.isFetching}
                update={item => this.updateFilter(item)}
                page={this.props.page}
                prevPage={() => this.prevPage()}
                nextPage={() => this.nextPage()}
                query={this.props.filter}
                results={this.props.results}
                title="Search Results"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ search }, props) {
  return {
    isFetching: search.isFetching,
    error: search.error,
    results: search.posts ? search.posts : {},
    query: props.location.query || {},
    filter: search.filter ? search.filter : {},
    page: search.page ? search.page : 1,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...suggestActionCreators,
      ...searchActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchQueryContainer);
