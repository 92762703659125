import React from 'react';

export default function Navigation({ menu, main }) {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="site-footer__row">
          <div className="site-footer__logo">
            <div className="logo-footer__section">
              <a
                href="https://analystinstitute.org"
                title="Analyst Institute"
                rel="noopener noreferrer"
              >
                <img
                  data-pix-no-hover="true"
                  className="logo-footer__img"
                  src="https://analysts-ppm.s3.amazonaws.com/uploads/2020/01/AISiteFooterLogo.png"
                  alt="Analyst Institute"
                />
              </a>
            </div>
          </div>
          <div className="site-footer__text">
            <p>
              Analyst Institute’s goal is for all progressive political and
              social justice campaigns to be informed by rigorous evidence.
            </p>
          </div>
          <div className="site-footer__nav">
            <h5 className="site-footer__nav--heading">Quick Links</h5>
            <ul className="nav-items">
              {menu[main] &&
                menu[main].items.map(({ title, url, id }) => (
                  <li key={id} className="nav-items__item">
                    <a
                      href={url}
                      target="_blank"
                      className="nav-link"
                      rel="noopener noreferrer"
                    >
                      {title}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="site-footer__legal">
          <p className="site-footer__legal--text">
            © {new Date().getUTCFullYear()} Analyst Institute. All Rights
            reserved. Built by{' '}
            <a
              href="http://www.plusplusminus.co.za/?utm_source=AnalystInstitute&utm_medium=Footer&utm_campaign=Credit"
              target="_blank"
              rel="noopener noreferrer"
              title="PlusPlusMinus Design &amp; Development"
            >
              PlusPlusMinus
            </a>
            .
          </p>
        </div>

        <div className="clearfix"></div>
      </div>
    </footer>
  );
}
