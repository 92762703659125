import React from 'react';
import { authContext } from '../../common/AuthContext';

export default function Navigation({ onAuth }) {
  const { setUnauthStatus } = React.useContext(authContext);
  return (
    <li className="menu-item--logout nav-items__item">
      <span onClick={event => setUnauthStatus()} className="nav-link" href="#">
        Log Out
      </span>
    </li>
  );
}
