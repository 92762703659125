import React, { useEffect, useState } from 'react';
import qs from 'qs';
import {
  Configure,
  InstantSearch,
  Pagination,
  ScrollTo,
  ClearRefinements,
  connectStateResults,
  connectRefinementList,
  connectSortBy,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
//import { Root } from './styles';
import { CustomHits, VirtualMenu } from './hitComps';
import { Filter, Relevance } from '../../components';
import CustomSearchBox from './input';

const CustomSortBy = connectSortBy(Relevance);
const CustomRefinementList = connectRefinementList(Filter);
const Stats = connectStateResults(({ searchResults: res }) => {
  if (!res) return null;
  const page = res.page + 1;
  return (
    res &&
    `Results ${(page - 1) * res.hitsPerPage + 1} -
                          ${
                            page * res.hitsPerPage <= res.nbHits
                              ? page * res.hitsPerPage
                              : res.nbHits
                          }
                          of ${res.nbHits}`
  );
});

const DEBOUNCE_TIME = 400;
export const searchClient = algoliasearch(
  '92MEDB00XO',
  'c1f65c24b4068edc520867d4101ff97d'
);

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = ({ location }, searchState) => {
  return searchState ? `${location.pathname}${createURL(searchState)}` : '';
};

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

export default function Search({ location, history, ...props }) {
  const { virtual, limit = 12 } = props;

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        history.push(
          searchStateToUrl({ location, history }, updatedSearchState),
          updatedSearchState
        );
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location.key]);

  return (
    <InstantSearch
      key={location.key}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      searchClient={searchClient}
      indexName={'research'}
    >
      <Configure hitsPerPage={limit} />
      {virtual
        ? virtual.map(v => {
            return (
              <VirtualMenu
                attribute={v.key}
                key={v.key}
                defaultRefinement={v.value}
              />
            );
          })
        : null}
      <ScrollTo>
        <div className="section-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="content-row">
                  <div className="search-container">
                    <div className="search-wrapper">
                      <div className="search__header">
                        <span className="search__sub-title">
                          <Stats />
                        </span>
                      </div>
                      <CustomSearchBox
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      />
                      <ClearRefinements
                        clearsQuery
                        translations={{
                          reset: 'Clear Search',
                        }}
                      />
                      <small>
                        <strong>Disclaimer:</strong> The material housed in this
                        password-protected website is confidential and only
                        intended to be viewed and utilized by members of the
                        Analyst Group. Please refrain from sharing any material
                        outside of the Group without explicit permission.
                        Maintaining confidentiality is essential to ensure that
                        partners and Analyst Group members are comfortable
                        sharing their findings with the community.
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="filter-col">
                    <CustomSortBy
                      defaultRefinement="research_date_desc"
                      title={'Sort Results'}
                      items={[
                        { value: 'research', label: 'Relevance' },
                        {
                          value: 'research_date_desc',
                          label: 'Newest first',
                        },
                        {
                          value: 'research_date_asc',
                          label: 'Oldest first',
                        },
                      ]}
                    />
                    <div className="meta-list-container m-t-1 m-b-1">
                      <span
                        style={{
                          textTransform: 'uppercase',
                          color: '#0b0b0b',
                          fontSize: '.6rem',
                          fontWeight: 600,
                        }}
                      >
                        Filters
                      </span>
                    </div>
                    <CustomRefinementList
                      attribute={'object_type'}
                      title="Types"
                    />
                    <CustomRefinementList
                      attribute={'authors'}
                      title="Authors"
                    />
                    <CustomRefinementList
                      limit={30}
                      attribute={'year'}
                      title="Year"
                    />
                    <CustomRefinementList
                      attribute={'organizations'}
                      title="Organizations"
                    />
                    <CustomRefinementList attribute={'tags'} title="Tags" />
                  </div>
                  <div className="results-col">
                    <CustomHits index={'research'} />
                    <Pagination />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollTo>
    </InstantSearch>
  );
}

Search.defaultProps = {
  indices: [],
  virtual: [],
  title: 'Search',
  hasFilter: true,
  isWrapped: true,
  isTable: false,
};
