import React from 'react';
import { SearchBox, RecommendedContainer } from '../../containers';

const QNAContainer = () => (
  <div className="section-wrapper">
    <div className="container">
      <SearchBox
        getTags="true"
        title="Questions & Answers"
        heading="What Q&A are you searching for?"
        object="qna"
        placeholder="Search Questions and Answers..."
        virtual={[{ key: 'object_type', value: 'qna' }]}
      />
      <RecommendedContainer tag="recommended" type="qna" />
    </div>
  </div>
);

export default QNAContainer;
