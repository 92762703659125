import styled from 'styled-components';
export const Root = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  grid-gap: 1em;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const HitsWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.5em);
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #29ae54;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 9999;
  box-shadow: 0 0 15px #e4e4e4;
  display: ${props => (props.show ? `grid` : `none`)};
  max-height: 30vh;
  overflow: scroll;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
`;
