import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CommonTags } from '../../containers';
import * as suggestActionCreators from '../../reducers/suggest';
import AutocompleteContainer from '../Algolia/AutocompleteContainer';

class SearchContainer extends React.Component {
  clearSearch(e) {
    e.preventDefault();
    this.props.resetFilter();
  }
  render() {
    let filter = {};

    if (this.props.object) {
      filter['object_type'] = this.props.object;
    }

    if (this.props.taxonomy) {
      filter[this.props.taxonomy.taxonomy] = this.props.taxonomy.name;
    }

    function renderContent(content) {
      return { __html: content };
    }

    return (
      <div className="content-row">
        <div className="search-container">
          <div className="search-wrapper">
            <div className="search__header">
              <h3 className="search__sub-title">{this.props.title || ''}</h3>
              <h2 className="search__title">
                {(
                  <span
                    dangerouslySetInnerHTML={renderContent(this.props.heading)}
                  />
                ) || 'What are you searching for?'}
              </h2>
            </div>

            <div className="sk-search-box">
              <AutocompleteContainer
                filter={filter}
                virtual={this.props.virtual}
              />
            </div>

            <p className="search__description">
              You can use keywords, titles, authors etc.
            </p>
            <small>
              <strong>Disclaimer:</strong> The material housed in this
              password-protected website is confidential and only intended to be
              viewed and utilized by members of the Analyst Group. Please
              refrain from sharing any material outside of the Group without
              explicit permission. Maintaining confidentiality is essential to
              ensure that partners and Analyst Group members are comfortable
              sharing their findings with the community.
            </small>

            {this.props.getTags ? (
              <CommonTags type={this.props.object} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ search }, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...suggestActionCreators,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
