import React from 'react';
import { SearchBox } from '../../containers';

const digestsContainer = () => {
  return (
    <div className="section-wrapper">
      <div className="container">
        <SearchBox
          getTags="true"
          title="Digests"
          heading="Which Digest are you searching for?"
          object="digest"
          placeholder="Search Digests..."
          virtual={[{ key: 'object_type', value: 'digest' }]}
        />
      </div>
    </div>
  );
};

export default digestsContainer;
