import React from 'react';
const Loader = () => (
  <div className="loader">
    <img
      alt="Loading"
      class="loader__img"
      src="https://s3.amazonaws.com/analysts-ppm/style/assets/images/loader.svg"
    />
  </div>
);

export default Loader;
