import React from 'react';
import { SearchResult, Filters, Relevance } from '../../components';
import Loader from '../Loader';

export default function Hits(props) {
  let num = 0;
  let pages = 0;

  if (props.results.hits) {
    num = props.results.hits.total || 0;
    pages = num / 12;
  }

  return (
    <div className="row">
      <div className="filter-col">
        <Relevance updateFilter={e => props.update({ sort: e.target.value })} />
        {props.results.aggregations ? (
          <Filters
            updateFilter={item => props.update(item)}
            query={props.query}
            aggregations={props.results.aggregations.filters}
          />
        ) : null}
      </div>
      <div className="results-col">
        {props.isFetching ? (
          <Loader />
        ) : (
          <div class="hits">
            <div className="row">
              {props.results.hits
                ? props.results.hits.hits.map(post => (
                    <SearchResult post={post} key={post._id} />
                  ))
                : null}
            </div>
            <div className="clearfix">
              <ul className="pager">
                {props.results.hits && props.page > 1 ? (
                  <li
                    className="pager__item pager__item--previous"
                    onClick={e => props.prevPage(e)}
                  >
                    <i className="icon icon-chevron-circle-left"></i> Previous
                  </li>
                ) : null}
                {props.results.hits && props.page < pages ? (
                  <li
                    className="pager__item pager__item--next"
                    onClick={e => props.nextPage(e)}
                  >
                    Next <i className="icon icon-chevron-circle-right"></i>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
