import React from 'react';
import { Link } from 'react-router-dom';

export default function Related({ posts, title }) {
  function renderContent(content) {
    return { __html: content };
  }
  return (
    <aside className="related">
      <div className="sidebar__header">
        <h3 className="sidebar__title">{title}</h3>
      </div>
      <ul className="sidebar-infos">
        {posts.map(post => (
          <li className="sidebar-info" key={post.id}>
            <h4 className="sidebar-info__title">
              <Link to={`/${post.type}/${post.slug}-${post.id}`}>
                <span dangerouslySetInnerHTML={renderContent(post.title)} />
              </Link>
            </h4>
          </li>
        ))}
      </ul>
    </aside>
  );
}
