import React from 'react';
import { Authenticate } from '../../components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActionCreators from '../../reducers/users';

class AuthenticateContainer extends React.Component {
  handleAuth = (e, creds) => {
    e.preventDefault();

    this.props.loginUser(creds);
  };
  render() {
    return (
      <Authenticate
        isFetching={this.props.isFetching}
        error={this.props.error}
        onAuth={this.handleAuth}
      />
    );
  }
}

function mapStateToProps({ users }) {
  return {
    isFetching: users.isFetching,
    error: users.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(userActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticateContainer);
