import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TagList } from '../../components';
import * as tagsActionCreators from '../../reducers/tags';

class CommonTags extends React.Component {
  componentDidMount() {
    this.props.fetchAndHandleTags({ object: this.props.type });
  }
  render() {
    return this.props.type === 'research' ||
      this.props.type === 'qna' ||
      this.props.type === 'digest' ||
      this.props.type === 'skills' ? (
      <TagList
        isFetching={this.props.isFetching}
        error={this.props.error}
        menu={this.props.tags}
        type={this.props.type}
        title="Tags"
      />
    ) : null;
  }
}

function mapStateToProps({ tags }) {
  return {
    isFetching: tags.isFetching,
    error: tags.error,
    tags: tags.tags ? tags.tags : [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...tagsActionCreators,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonTags);
