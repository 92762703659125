import React from 'react';

class Permissions extends React.Component {
  render() {
    const imgUrl =
      'https://analystinstitute.org/wp-content/uploads/2016/05/bg.jpg';
    const divStyle = {
      backgroundImage: 'url(' + imgUrl + ')',
      backgroundSize: 'cover',
    };
    return (
      <div className="section-wrapper" style={divStyle}>
        <div className="container">
          <div className="logo-row">
            <div className="logo-home__section">
              <a href="https://analystinstitute.org" title="Analyst Institute">
                <img
                  data-pin-no-hover="true"
                  className="logo-home__img"
                  src="https://s3.amazonaws.com/analysts-ppm/style/assets/images/ai-home-logo.png"
                  alt="Analyst Institute"
                />
              </a>
            </div>
          </div>
          <main className="row page-content">
            <div className="page-content__header" style={{ maxWidth: '70%' }}>
              <p className="lead">
                You attempted to reach a members-only page.
              </p>
              <p>
                The Analyst Institute collaborates with progressive
                organizations and Democratic campaigns around the country to
                measure and increase the impact of their programs.
              </p>
              <a className="btn-link" href="https://analystinstitute.org/join/">
                <span className="icon icon-chevron-circle-right"></span>
                <span className="btn-link__label"> Apply Now</span>
              </a>
              <a className="btn-link" href="https://analystinstitute.org/">
                <span className="icon icon-chevron-circle-right"></span>
                <span className="btn-link__label"> Back to homepage</span>
              </a>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default Permissions;
