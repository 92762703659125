import { fetchLogin, fetchUser } from '../helpers/api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export function requestLogin() {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}

export function receiveLogin(access_token, refresh_token) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: true,
    isAuthenticated: false,
    access_token: access_token,
    refresh_token: refresh_token,
  };
}

export function receiveUser(user) {
  return {
    type: USER_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user,
  };
}

export function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
    access_token: null,
    refresh_token: null,
    user: null,
  };
}

export function loginUser(creds) {
  return dispatch => {
    dispatch(requestLogin());

    fetchLogin(creds)
      .then(response => {
        const { access_token, refresh_token } = response.data.token;

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);

        dispatch(receiveLogin(access_token, refresh_token));

        return fetchUser();
      })
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data));

        dispatch(receiveUser(response.data));
      })
      .catch(response => {
        dispatch(loginError(response.data));
      });
  };
}

export function verifyUser(token) {
  return dispatch => {
    dispatch(requestLogin());

    fetchUser(token)
      .then(response => {
        const { access_token, refresh_token } = token;

        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('user', JSON.stringify(response.data));

        dispatch(receiveLogin(access_token, refresh_token));
        dispatch(receiveUser(response.data));
      })
      .catch(response => {
        dispatch(loginError(response.data));
      });
  };
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return dispatch => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    dispatch(receiveLogout());
  };
}

let accessToken = localStorage.getItem('access_token') || null;
let refreshToken = localStorage.getItem('refresh_token') || null;
let user = JSON.parse(localStorage.getItem('user')) || null;
let isAuthenticated = false;

if (accessToken && refreshToken && user) {
  isAuthenticated = true;
}

const initialState = {
  isFetching: false,
  error: '',
  isAuthenticated: isAuthenticated,
  user: user,
  access_token: accessToken,
  refresh_token: refreshToken,
};

function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        errorMessage: '',
        access_token: action.access_token,
        refresh_token: action.refresh_token,
      });
    case USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        user: action.user,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
      });
    default:
      return state;
  }
}

export default auth;
